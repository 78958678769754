import type { FeatureFlags } from '@medsimples/doctor-onboarding-openapi-v2';
import { useQuery } from '@tanstack/react-query';
import { type ReactNode, createContext, useContext } from 'react';
import { onboardingAPIV2Client } from '../../api';

const FeatureFlagContext = createContext<FeatureFlags>({} as FeatureFlags);

const FeatureFlagProvider = ({ children }: { children: ReactNode }) => {
  const { data } = useQuery({
    placeholderData: {} as FeatureFlags,
    queryKey: ['featureFlags'],
    queryFn: () =>
      onboardingAPIV2Client.admin.listFeatureFlags().then((r) => r.data),
    retry: 3,
  });

  return (
    <FeatureFlagContext.Provider value={data}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export default FeatureFlagProvider;

export const useFlags = () => useContext(FeatureFlagContext);
