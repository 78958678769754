'use client';

import { handleAPIReq, onboardingAPIV2Client } from '@/api';
import DoctorsTable from '@/app/components/doctors/table';
import { useDebounce } from '@/hooks/useDebounce';
import { tokens } from '@/styles/tokens';
import { type StatusLabel, statusName } from '@/utils/constants';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Button,
  Card,
  Center,
  Flex,
  Input,
  Spinner,
  Tab,
  TabList,
  Tabs,
} from '@chakra-ui/react';
import { unaccentLower } from '@commons/js-utils';
import { ProfessionalStatus } from '@medsimples/doctor-onboarding-openapi-v2';
import { useQuery } from '@tanstack/react-query';
import * as E from 'fp-ts/Either';
import { useSearchParams } from 'next/navigation';
import { Suspense, useState } from 'react';
import ExportReportModal from '../components/modals/exportReportModal';
import { useAuth } from '../providers/auth_provider';
import { useFlags } from '../providers/feature_flag_provider';

function DoctorsPage() {
  const searchParams = useSearchParams();
  const auth = useAuth();
  const ffs = useFlags();

  const [currentStatus, setCurrentStatus] = useState<StatusLabel[]>([]);
  const [textSearch, setTextSearch] = useState(
    searchParams.get('search') ?? '',
  );
  const textSearchDebounced = useDebounce(textSearch, 700);
  const [isLoading, setIsLoading] = useState(false);
  const [isExportReportModalOpen, setIsExportReportModalOpen] = useState(false);

  const { data: statusCount } = useQuery({
    queryKey: ['statusCount'],
    enabled: Boolean(auth.user),
    queryFn: async () => {
      const r = await handleAPIReq(() =>
        onboardingAPIV2Client.admin.statusesCount(),
      );
      if (E.isLeft(r)) {
        throw r.left;
      }
      return r.right.data;
    },
  });

  const closeExportModal = async (
    emitReport?: boolean,
    withFilters?: boolean,
  ) => {
    if (emitReport) {
      setIsLoading(true);
      const bufferReport = await onboardingAPIV2Client.admin
        .exportReport(
          withFilters
            ? {
                search: searchParams.get('search'),
                sorting: {
                  sort: searchParams.get('sort'),
                  order: searchParams.get('order'),
                },
                filters: searchParams.get('filters')
                  ? JSON.parse(searchParams.get('filters'))
                  : {},
              }
            : {
                search: null,
                sorting: {
                  sort: null,
                  order: null,
                },
                filters: searchParams.get('filters')
                  ? JSON.parse(searchParams.get('filters'))
                  : {},
              },
        )
        .finally(() => setIsLoading(false));

      const filters = searchParams.get('filters')
        ? JSON.parse(searchParams.get('filters'))
        : {};

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(bufferReport);
      link.download = unaccentLower(
        `medicos-${
          statusName[filters.status || 'ALL']
        }-${new Date().toLocaleDateString('pt-BR')}.xlsx`.replaceAll(' ', '-'),
      );
      link.click();
    }
    setIsExportReportModalOpen(false);
  };

  const { data: admin, isFetching: isAdminFetching } = useQuery({
    queryKey: ['me'],
    enabled: Boolean(auth.user),
    queryFn: () => {
      return handleAPIReq(() => onboardingAPIV2Client.admin.meAdmin()).then(
        (res) => {
          if (E.isLeft(res)) {
            throw res.left;
          }
          return res.right.data;
        },
      );
    },
  });

  const findStatusIndex = (value: StatusLabel) => {
    const filters = JSON.parse(searchParams.get('filters'));
    return filters?.status?.length === 1
      ? filters.status[0] === value
      : 'ALL' === value;
  };

  const isAnyLoading = isLoading || isAdminFetching;

  return auth.user ? (
    <Card width={'100%'} paddingY={6} paddingX={6}>
      <Flex justifyContent='flex-start' alignItems='center' marginBottom={8}>
        <Tabs
          width='100%'
          variant='soft-rounded'
          colorScheme='blue'
          index={Object.keys(statusName).findIndex(findStatusIndex)}
          onChange={(index) =>
            setCurrentStatus(
              Object.keys(statusName)[index] === 'ALL'
                ? []
                : ([Object.keys(statusName)[index]] as StatusLabel[]),
            )
          }
        >
          <TabList width='100%' justifyContent='center'>
            {Object.entries(statusName)
              .filter(
                ([status]) =>
                  // ignore pre-approval status if feature is disabled
                  ffs.ENABLE_PRE_APPROVAL ||
                  ![
                    ProfessionalStatus.WAITING_PRE_APPROVAL,
                    ProfessionalStatus.PRE_APPROVED,
                  ].includes(status as ProfessionalStatus),
              )
              .map(([status, statusLabel]) => (
                <Tab
                  key={status}
                  fontWeight={
                    findStatusIndex(status as StatusLabel) ? 500 : 300
                  }
                  fontSize={14}
                  isDisabled={isAnyLoading}
                >
                  {`${statusLabel} (${statusCount?.[status] ?? 0})`}
                </Tab>
              ))}
          </TabList>
        </Tabs>
      </Flex>

      <Flex
        justifyContent='flex-start'
        alignItems='center'
        flexWrap='nowrap'
        background={tokens.background.ice.color}
        paddingX={8}
        paddingY={6}
        marginBottom={8}
      >
        <Input
          placeholder='Digite um CPF, CNPJ, Nº Conselho, BP ou nome para pesquisar'
          value={textSearch}
          onChange={(e) => setTextSearch(e.target.value)}
          isDisabled={isAnyLoading}
          variant='outline'
          background='white'
          marginRight={4}
        />
        <Button
          variant={'outline'}
          colorScheme={tokens.button.primary.scheme}
          fontWeight={400}
          backgroundColor={tokens.text.white.color}
          size={'lg'}
          isDisabled={isAnyLoading}
          onClick={() => setIsExportReportModalOpen(true)}
          leftIcon={<ExternalLinkIcon />}
        >
          Exportar relatório
        </Button>
      </Flex>
      <DoctorsTable
        textSearch={textSearchDebounced as string}
        tabStatus={currentStatus}
        admin={admin}
        resetSearch={() => setTextSearch('')}
      />
      <ExportReportModal
        isOpen={isExportReportModalOpen}
        closeModal={closeExportModal}
        isLoading={isLoading}
      />
    </Card>
  ) : (
    <Center width={'100%'} marginTop={'30vh'} verticalAlign={'center'}>
      <Spinner size={'xl'} />
    </Center>
  );
}

export default function DoctorsPageSuspense() {
  return (
    <Suspense>
      <DoctorsPage />
    </Suspense>
  );
}
